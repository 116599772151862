import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import FluidImage from '../components/FluidImage';
import Layout from '../components/Layout';
import SignUpForm from '../components/SignUpForm';

const IndexPage = (props) => {
  const { data } = props;

  const {
    firstBubble,
    secondBubble,
    thirdBubble,

    clap,
    pluger,
    vault,

    firstHow,
    secondHow,
    thirdHow,

    squareSpace,
    weebly,
    wix,
    wordpress,
  } = data;

  return (
    <Layout {...props} isDark>
      <Helmet>
        <title>A floating contact form for your website or app | ContactBubble</title>
        <meta
          name="description"
          content="Frictionless customer interactions for makers and small teams. ContactBubble is an embeddable contact form that runs on email."
        />

        <meta
          name="og:title"
          content="A floating contact form for your website or app | ContactBubble"
        />
        <meta
          name="og:description"
          content="Frictionless customer interactions for makers and small teams. ContactBubble is an embeddable contact form that runs on email."
        />
        <meta name="og:url" content="https://www.contactbubble.com" />
      </Helmet>

      <div className="fixed bottom-0 right-0 mr-24 mb-4 text-grey-dark bg-white shadow-lg rounded-full py-3 px-4 text-lg z-50 rubber-band">
        <code className="text-grey-darker">
          <span className="hidden md:inline-block">This. Is. ContactBubble.</span>
          <span className="inline-block md:hidden">Your bubble</span>
        </code>
        {' '}
        →
      </div>

      <div className="bg-bubbles text-white py-24">
        <div className="container">
          <div className="w-full text-center pb-4">
            <h1 className="text-white font-light leading-tight">
              A
              {' '}
              <strong className="font-bold">floating contact form</strong>
              <br />
              {' '}
              <span>for your </span>
              <strong className="font-bold">website</strong>
              {' '}
or
              <strong className="font-bold"> app</strong>
            </h1>
            <h2 className="text-white h3 font-normal">
              Smooth customer interactions
              {' '}
              <br />
              {' '}
for
              {' '}
              <strong>small businesses</strong>
            </h2>
          </div>
          <div
            className="mt-4 mx-auto block w-full max-w-lg absolute"
            style={{ left: '50%', transform: 'translateX(-50%)' }}
          >
            <div className="bg-offwhite p-8 rounded-lg shadow-xl border-2 border-gray-300">
              <SignUpForm />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-48">
        <div className="container-md">
          <h2 className="font-serif text-center mb-8">
            Why you want your
            {' '}
            <br />
            bubble...
          </h2>
        </div>
      </div>
      <div>
        <div className="container flex flex-padded">
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <div className="callout h-full p-8 pt-12">
              <h3>
                <FluidImage image={pluger} style={{ width: '80px' }} />
                <div>Accessible</div>
              </h3>
              <p className="text-lg">
                Your contact bubble is accessible from the bottom right corner on every page. So
                your users and customers will always find you.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <div className="callout h-full p-8 pt-12">
              <h3>
                <FluidImage image={clap} style={{ width: '80px' }} />
                {' '}
                <div>Runs on email</div>
              </h3>
              <p className="text-lg">
                The messages are send to your e-mail. That is also where your customer conversation
                will continue. Hassle free. In an environment optimised for interaction.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <div className="callout h-full p-8 pt-12">
              <h3>
                <FluidImage image={vault} style={{ width: '80px' }} />
                {' '}
                <div>Affordable</div>
              </h3>
              <p className="text-lg">
                Our focus keeps us affordable. Modern marketing platforms are stuffed with features
                you don’t need and use, but you do pay for. Not us. We keep it simple and
                affordable.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-12">
        <div className="relative text-white bg-primary-500 py-12">
          <div>
            <div className="container">
              <h2 className="text-center mb-8 text-white">
                Why we prefer our floating contact form
                {' '}
                <br className="hidden lg:block" />
                over alternatives
              </h2>

              <div className="flex flex-padded-lg flex-wrap mb-8">
                <div className="w-full md:w-1/2">
                  <h3 className="text-white">We don't love chat</h3>
                  <p className="text-lg mb-0">
                    Chat has the worst user experience. For both you and your customer. Chat leads
                    to half questions, which you can’t answer. And users expect instant feedback,
                    which you can’t give.
                  </p>
                </div>
                <div className="w-full md:w-1/2">
                  <h3 className="text-white">Contact forms also suck</h3>
                  <p className="text-lg mb-0">
                    You don’t have to build, deploy and maintain a custom contact form. Besides, our
                    floating feedback form offers a superior user experience, since it’s always
                    available.
                  </p>
                </div>
              </div>

              <div className="w-full h-2 border-dashed border-b-2 border-primary-600" />

              <div className="flex flex-padded-lg flex-wrap my-8">
                <div className="w-full md:w-1/2">
                  <h3 className="text-white">Your data is your data</h3>
                  <p className="text-lg">
                    Many third party tools collect all kinds of data. Data about you. Data about
                    your users. Not us. We respect your privacy. Also, expect fast and secure
                    contact forms.
                  </p>
                </div>
                <div className="w-full md:w-1/2">
                  <h3 className="text-white">Centralised interactions</h3>
                  <p className="text-lg">
                    ContactBubble centralizes your customer interactions. No need to manage
                    different channels. Which is perfect for single founders and small businesses.
                  </p>
                </div>
              </div>

              <div className="w-full h-2 border-dashed border-b-2 border-primary-600" />

              <div className="flex flex-padded-lg flex-wrap mt-8">
                <div className="w-full md:w-1/2">
                  <h3 className="text-white">Email is king</h3>
                  <p className="text-lg">
                    E-mail is optimised for interaction. You can use your native platform or client
                    that you already use and love. You can create a dedicated support account if you
                    don’t want to clutter your inbox.
                  </p>
                </div>
                <div className="w-full md:w-1/2">
                  <h3 className="text-white">Make money and save money</h3>
                  <p className="text-lg">
                    Allowing easy customer interactions will have positive effects down the line.
                    Which will make you money. And being affordable, you’ll save money as well.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-12">
        <div className="container">
          <h2 className="text-center pb-12">
            How your bubble
            <br />
            works...
          </h2>

          <div className="flex flex-padded items-center pb-12">
            <div className="w-full md:w-1/2">
              <div className="p-12 md:py-0 md:px-12">
                <FluidImage image={firstHow} className="w-full" />
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <h3>Setup & Embed</h3>
              <p className="text-lg">
                Setup your floating contact form and customise your greeting, intro and your button
                colour. By copying the embed code into your website you activate your ContactBubble.
                That's it.
              </p>
            </div>
          </div>

          <div className="flex md:flex-row-reverse flex-padded items-center pb-12">
            <div className="w-full md:w-1/2">
              <div className="p-12 md:py-0 md:px-20">
                <FluidImage image={secondHow} className="w-full" />
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <h3>Engage</h3>
              <p className="text-lg">
                Your ContactBubble is accessible from the bottom-right corner of each page. Your
                users activate the customer contact form by clicking the button. When the message is
                send, the conversation will continue through mail.
              </p>
              <p className="text-lg">
                Check out how this
                {' '}
                <a href="https://www.convertcalculator.co">calculator builder</a>
                {' '}
                uses ContactBubble to engage with customers.
              </p>
            </div>
          </div>

          <div className="flex flex-padded items-center mb-16">
            <div className="w-full md:w-1/2">
              <div className="p-12 md:py-0 md:px-20">
                <FluidImage image={thirdHow} className="w-full" />
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <h3>Interact</h3>
              <p className="text-lg">
                When the message is send, conversation will continue in your e-mail client. The
                original message is enriched with data, such as time-zone, webpage where question
                was initiated and browser version. All the info you need to start your interaction.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-800 text-white py-24">
        <h2 className="text-center mb-12 text-white">
          Embedding your floating contact form is easy
          {' '}
          <br />
          on these platforms
        </h2>
        <div className="container flex flex-wrap">
          <div className="w-1/2 md:w-1/4 text-center px-2">
            <div className="text-gray-100">
              <FluidImage
                image={squareSpace}
                altText="ConvertCalculator is really easy to embed on Squarespace"
              />

              <h3 className="h6 pt-4 mb-0 text-gray-100">Squarespace</h3>
              <p>Build a contact form widget for your Squarespace website</p>
            </div>
          </div>
          <div className="w-1/2 md:w-1/4 text-center px-2">
            <div className="text-gray-100">
              <FluidImage
                image={weebly}
                altText="ConvertCalculator is really easy to embed on Weebly"
              />

              <h3 className="h6 pt-4 mb-0 text-gray-100">Weebly</h3>
              <p>Create a customer service form for your Weebly website</p>
            </div>
          </div>
          <div className="w-1/2 md:w-1/4 text-center px-2">
            <div className="text-gray-100">
              <FluidImage
                image={wix}
                altText="ConvertCalculator is really easy to integrate with Wix"
              />

              <h3 className="h6 pt-4 mb-0 text-gray-100">Wix</h3>
              <p>Create a floating feedback form for Wix and embed it in minutes.</p>
            </div>
          </div>
          <div className="w-1/2 md:w-1/4 text-center px-2">
            <div className="text-gray-100">
              <FluidImage
                image={wordpress}
                altText="Easily embed your floating contact for on your Wordpress website"
              />

              <h3 className="h6 pt-4 mb-0 text-gray-100">Wordpress</h3>
              <p>Easily embed your floating contact form on your Wordpress website</p>
            </div>
          </div>
        </div>

        <h3 className="h6 text-center text-gray-100 mt-8">
          The floating contact form also works on Webflow , Godaddy , Hubspot , CraftCMS,
          ExpressionEngine, and a lot more...
        </h3>
      </div>

      <div className="pt-16">
        <div className="container-md">
          <div className="callout border-dashed border-grey-darkest border-2 p-8">
            <h3>Get your ContactBubble now!</h3>
            <SignUpForm
              header={(
                <h4 className="mb-0">
                  Start for
                  {' '}
                  <em className="border-b-2 border-white">free</em>
                </h4>
              )}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexImageQuery {
    waveImg: file(relativePath: { eq: "wave.png" }) {
      ...FluidLargeImage
    }
    triangularImg: file(relativePath: { eq: "triangular.png" }) {
      ...FluidLargeImage
    }

    firstBubble: file(relativePath: { eq: "bubble-1.png" }) {
      ...FluidSmallImage
    }
    secondBubble: file(relativePath: { eq: "bubble-2.png" }) {
      ...FluidSmallImage
    }
    thirdBubble: file(relativePath: { eq: "bubble-3.png" }) {
      ...FluidSmallImage
    }

    clap: file(relativePath: { eq: "clap.png" }) {
      ...FluidIconImage
    }
    pluger: file(relativePath: { eq: "pluger.png" }) {
      ...FluidIconImage
    }
    vault: file(relativePath: { eq: "vault.png" }) {
      ...FluidIconImage
    }

    firstHow: file(relativePath: { eq: "how-1.png" }) {
      ...FluidMediumImage
    }
    secondHow: file(relativePath: { eq: "how-2.png" }) {
      ...FluidMediumImage
    }
    thirdHow: file(relativePath: { eq: "how-3.png" }) {
      ...FluidMediumImage
    }

    squareSpace: file(relativePath: { eq: "platforms/squarespace.png" }) {
      ...FluidSmallImage
    }
    weebly: file(relativePath: { eq: "platforms/weebly.png" }) {
      ...FluidSmallImage
    }
    wix: file(relativePath: { eq: "platforms/wix.png" }) {
      ...FluidSmallImage
    }
    wordpress: file(relativePath: { eq: "platforms/wordpress.png" }) {
      ...FluidSmallImage
    }
  }
`;
